import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PostTitle from '../components/post-title';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { NavigateNext, NavigateBefore } from '@material-ui/icons';

import Styles from './blog-post.module.scss';

const styles = theme =>
  createStyles({
    blogContainer: {
      backgroundColor: 'white',
      padding: '5% 3%'
    },
    blogFooter: {
      padding: '1.2rem 0',
      fontWeight: 'bold'
    },
    icon: {
      fontSize: '1.2rem',
      paddingTop: '0.5rem'
    },
    link: {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.light
      },
      '&:visited': {
        color: theme.palette.primary.dark
      },
    }
  });

const BlogPostTemplate = ({ data, pageContext, location, classes }) => {
  const post = data.markdownRemark;
  const { previous, next } = pageContext;

  return (
    <Layout location={location}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        keywords={post.frontmatter.keywords}
        type="article"
      />
      <div className={classes.blogContainer}>
        <PostTitle
          title={post.frontmatter.title}
          date={post.frontmatter.date}
          description={post.frontmatter.description}
        />
        <div className={Styles.blogContents}>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
        <Grid
          className={classes.blogFooter}
          container
          direction="row"
          wrap="nowrap"
          justify="center"
        >
          {previous && (
            <>
              <Grid item container direction="column">
                <Link to={previous.fields.slug} rel="prev" className={classes.link}>
                  <Typography color="textSecondary" variant="h6">
                    <NavigateBefore className={classes.icon} /> 前の記事
                  </Typography>
                  <Typography color="inherit">
                    {previous.frontmatter.title}
                  </Typography>
                </Link>
              </Grid>
            </>
          )}
          {next && (
            <>
              <Grid
                item
                container
                direction="column"
                alignItems="flex-end"
              >
                <Link to={next.fields.slug} rel="next" className={classes.link}>
                  <Typography color="textSecondary" variant="h6" align="right">
                    次の記事
                    <NavigateNext className={classes.icon} />
                  </Typography>
                  <Typography color="inherit" align="right">
                    {next.frontmatter.title}
                  </Typography>
                </Link>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </Layout>
  );
};

export default withStyles(styles)(BlogPostTemplate);

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        keywords
        description
      }
    }
  }
`;
