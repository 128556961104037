import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const styles = theme =>
  createStyles({
    postTitleBox: {
      marginTop: '5%',
      marginBottom: '1.6rem'
    },
    title: {
      margin: '0.8rem 0'
    },
    date: {
      margin: '0.8rem 0'
    }
  });

const PostTitle = ({ title, date, description, classes }) => (
  <Grid className={classes.postTitleBox} direction="column" container alignItems="center">
    <Typography color="textPrimary" variant="h4" className={classes.title}>
      {title}
    </Typography>
    <Typography color="textSecondary" variant="body2" className={classes.date}>
      {date}
    </Typography>
    <Typography color="textSecondary" variant="subtitle1">
      {description}
    </Typography>
  </Grid>
);

export default withStyles(styles)(PostTitle);
